<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>医院数据</span>
            </div>
            <div class="tablemain">
                <el-row>
                    <el-col :span="6" class="cols6">
                        <div class="">医院数量</div>
                        <div class=""><span class="number">{{ this.hospitalInfo.hospitalNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card cardbox">
            <div slot="header" class="clearfix">
                <span>用户数据</span>
            </div>
            <div class="tablemain">
                <el-row>
                    <el-col :span="6" class="cols6">
                        <div class="">用户总量</div>
                        <div class=""><span class="number">{{ this.consumerInfo.consumerNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="6" class="cols6">
                        <div class="">陪护床用户数</div>
                        <div class=""><span class="number">{{ this.consumerInfo.bedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="6" class="cols6">
                        <div class="">轮椅用户数</div>
                        <div class=""><span class="number">{{ this.consumerInfo.chairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="6" class="cols6">
                        <div class="">无屏售卖用户数</div>
                        <div class=""><span class="number">{{ this.consumerInfo.noScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
                <el-row class="table-row">
                    <el-col :span="4" class="cols6">
                        <div class="">昨日新增</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">总数</div>
                        <div class=""><span class="number">{{ this.consumerInfo.yesterdayNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">陪护床</div>
                        <div class=""><span class="number">{{ this.consumerInfo.yesterdayBedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅</div>
                        <div class=""><span class="number">{{ this.consumerInfo.yesterdayChairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">无屏售卖</div>
                        <div class=""><span class="number">{{ this.consumerInfo.yesterdayNoScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
                <el-row class="table-row">
                    <el-col :span="4" class="cols6">
                        <div class="">当月新增</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">总数</div>
                        <div class=""><span class="number">{{ this.consumerInfo.monthNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">陪护床</div>
                        <div class=""><span class="number">{{ this.consumerInfo.monthBedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅</div>
                        <div class=""><span class="number">{{ this.consumerInfo.monthChairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">无屏售卖</div>
                        <div class=""><span class="number">{{ this.consumerInfo.monthNoScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card cardbox">
            <div slot="header" class="clearfix">
                <span>设备数据</span>
            </div>
            <div class="tablemain" >
                <el-row>
                    <el-col :span="4" class="cols6">
                        <div class="">陪护床设备数据</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">设备在线数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.bedOnlineNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">设备离线数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.bedOfflineNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">床借出数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.bedLendNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
                <el-row class="table-row">
                    <el-col :span="4" class="cols6">
                        <div class="">轮椅设备数据</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">设备在线数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.chairOnlineNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">设备离线数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.chairOfflineNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅借出数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.chairLendNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
                <el-row class="table-row">
                    <el-col :span="4" class="cols6">
                        <div class="">无屏售卖设备数据</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">设备在线数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.noScreenOnlineNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">设备离线数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.noScreenOfflineNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">补货数</div>
                        <div class=""><span class="number">{{ this.deviceInfo.noScreenLendNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card cardbox">
            <div slot="header" class="clearfix">
                <span>订单数据</span>
            </div>
            <div class="tablemain" >
                <el-row>
                    <el-col :span="4" class="cols6">
                        <div class="">昨日</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">总数</div>
                        <div class=""><span class="number">{{ this.orderInfo.yesterdayNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">陪护床</div>
                        <div class=""><span class="number">{{ this.orderInfo.yesterdayBedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅</div>
                        <div class=""><span class="number">{{ this.orderInfo.yesterdayChairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">无屏售卖</div>
                        <div class=""><span class="number">{{ this.orderInfo.yesterdayNoScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
                <el-row class="table-row">
                    <el-col :span="4" class="cols6">
                        <div class="">当月</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">总数</div>
                        <div class=""><span class="number">{{ this.orderInfo.monthNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">陪护床</div>
                        <div class=""><span class="number">{{ this.orderInfo.monthBedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅</div>
                        <div class=""><span class="number">{{ this.orderInfo.monthChairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">无屏售卖</div>
                        <div class=""><span class="number">{{ this.orderInfo.monthNoScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card cardbox">
            <div slot="header" class="clearfix">
                <span>收入数据</span>
            </div>
            <div class="tablemain" >
                <el-row>
                    <el-col :span="4" class="cols6">
                        <div class="">昨日</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">总收入</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.yesterdayNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">陪护床</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.yesterdayBedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.yesterdayChairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">无屏售卖</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.yesterdayNoScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
                <el-row class="table-row">
                    <el-col :span="4" class="cols6">
                        <div class="">当月</div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">总收入</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.monthNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">陪护床</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.monthBedNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">轮椅</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.monthChairNumber || 0 }}</span></div>
                    </el-col>
                    <el-col :span="5" class="cols6">
                        <div class="">无屏售卖</div>
                        <div class=""><span class="number">￥{{ this.incomeInfo.monthNoScreenNumber || 0 }}</span></div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "dashboard",
        data() {
            return {
                hospitalInfo: {
                    hospitalNumber:0
                },
                consumerInfo: {
                    consumerNumber:0,
                    bedNumber:0,
                    chairNumber:0,
                    noScreenNumber:0,
                    yesterdayNumber:0,
                    yesterdayBedNumber:0,
                    yesterdayChairNumber:0,
                    yesterdayNoScreenNumber:0,
                    monthNumber:0,
                    monthBedNumber:0,
                    monthChairNumber:0,
                    monthNoScreenNumber:0
                },
                deviceInfo: {
                    bedOnlineNumber:0,
                    bedOfflineNumber:0,
                    bedLendNumber:0,
                    chairOnlineNumber:0,
                    chairOfflineNumber:0,
                    chairLendNumber:0,
                    noScreenOnlineNumber:0,
                    noScreenOfflineNumber:0,
                    noScreenLendNumber:0
                },
                orderInfo: {
                    yesterdayNumber:0,
                    yesterdayBedNumber:0,
                    yesterdayChairNumber:0,
                    yesterdayNoScreenNumber:0,
                    monthNumber:0,
                    monthBedNumber:0,
                    monthChairNumber:0,
                    monthNoScreenNumber:0
                },
                incomeInfo: {
                    yesterdayNumber:0,
                    yesterdayBedNumber:0,
                    yesterdayChairNumber:0,
                    yesterdayNoScreenNumber:0,
                    monthNumber:0,
                    monthBedNumber:0,
                    monthChairNumber:0,
                    monthNoScreenNumber:0
                }
            }
        },
        methods:{
            getHospitalInfo(){
                this.$axios.get("/sys-api/site-api/getHospitalInfo",{},(res) => {
                    if(res.code === '100' && res.data != null){
                        this.hospitalInfo = res.data;
                    }
                })
            },
            getConsumerInfo(){
                this.$axios.get("/sys-api/site-api/getConsumerInfo",{},(res) => {
                    if(res.code === '100' && res.data != null){
                        this.consumerInfo = res.data;
                    }
                })
            },
            getDeviceInfo(){
                this.$axios.get("/sys-api/site-api/getDeviceInfo",{},(res) => {
                    if(res.code === '100' && res.data != null){
                        this.deviceInfo = res.data;
                    }
                })
            },
            getOrderInfo(){
                this.$axios.get("/sys-api/site-api/getOrderInfo",{},(res) => {
                    if(res.code === '100' && res.data != null){
                        this.orderInfo = res.data;
                    }
                })
            },
            getIncomeInfo(){
                this.$axios.get("/sys-api/site-api/getIncomeInfo",{},(res) => {
                    if(res.code === '100' && res.data != null){
                        this.incomeInfo = res.data;
                    }
                })
            }
        },
        mounted() {
            //调用方法
            this.$nextTick(() => {
                this.getHospitalInfo();
                this.getConsumerInfo();
                this.getDeviceInfo();
                this.getOrderInfo();
                this.getIncomeInfo();
            })
        }
    }
</script>

<style scoped>
    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }
    .clearfix{
        font-weight: bold;
    }

    .box-card {
        width: auto;
    }
    .cardbox{
        margin-top: 5px;
    }
    .cols6{
        text-align: center;
    }
    .table-row{
        margin-top: 30px;
    }
</style>